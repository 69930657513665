'use strict';

(function () {
  'use strict';

  angular.module('app').directive('searchBox', searchBox);

  function searchBox() {
    return {
      scope: {},
      replace: true,
      transclude: true,
      controller: searchBoxController,
      controllerAs: 'ctrl',
      bindToController: {
        model: '=',
        title: '@',
        info: '@',
        send: '&',
        clear: '&',
        focus: '&'
      },
      templateUrl: 'app/directives/searchBox.html'
    };

    function searchBoxController() {}
  }
})();